import cloneDeep from 'lodash/cloneDeep';
import compact from 'lodash/compact';
import find from 'lodash/find';
import forEach from 'lodash/forEach';
import map from 'lodash/map';
import React, { useContext, useReducer, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import AttributeSelector from '@cimpress-technology/generic-selector';
import { Alert, CodeBlock } from '@cimpress/react-components';
import Button from '@cimpress/react-components/lib/Button';
import Card from '@cimpress/react-components/lib/Card';
import TextField from '@cimpress/react-components/lib/TextField';
import Tooltip from '@cimpress/react-components/lib/Tooltip';

import auth from '../../auth';
import { createTestEvaluateRequestFromState } from '../../helpers/createMappingSetRequestFromState';
import { createStateFromMappingSet } from '../../helpers/createStateFromMappingSet';
import { validateMappingSet } from '../../helpers/validators';
import { useAccountByUrl } from '../../hooks/useAccounts';
import { useGroupId } from '../../hooks/useCoam';
import useDebounce from '../../hooks/useDebounce';
import useFulfillmentConfigurationSearch from '../../hooks/useFulfillmentConfigurationSearch';
import useNewPackMappingSet from '../../hooks/useNewPackMappingSet';
import usePackMappingSet from '../../hooks/usePackMappingSet';
import useTestEvaluation from '../../hooks/usePackTestEvaluation';
import { useUcnContext } from '../../hooks/useUcnContext';
import useUpdatePackMappingSet from '../../hooks/useUpdatePackMappingSet';
import { addPackMapping, editMappingSet, rehydrateMappingSet } from '../../reducers/editActions';
import { createInitialPackMappingSetState, createInitialPackCalculatorState } from '../../reducers/editConstants';
import editReducer from '../../reducers/editReducer';
import Loading from '../Loading';
import ErrorDisplay from '../shared/ErrorDisplay';
import SaveButton from '../shared/SaveButton';
import { SnackBarContext } from '../shared/snackbarContext';
import { FcData, MappingSetState, MappingState, MatchProps, SelectField } from '../shared/types';
import AccountSelect from './AccountSelect';
import PackCalculatorMapping, { AttributeModelRef } from './PackCalculatorMapping';
import messages from './messages';

const CreatePage = ({ match }: MatchProps) => {
  const { id } = match.params;
  const history = useHistory();
  const { formatMessage } = useIntl();

  const { setShowSnackbar, setSnackbarDetails } = useContext(SnackBarContext);
  const attributeSelector = useRef<{ getConfigurationUrl: () => string }>();
  const [accountSelection, setAccountSelection] = useState('');
  const [refs, setRefs] = useState<Record<string, AttributeModelRef | null>>({});
  const [mappingSet, dispatch] = useReducer(editReducer, createInitialPackMappingSetState());
  const [isUsedInFCs, setIsUsedInFCs] = useState(false);
  const [testSku, setTestSku] = useState('');
  const debouncedTestSku = useDebounce(testSku, 400);
  const [deliverableQuantity, setDeliverableQuantity] = useState<string>();
  const {
    data: testResult,
    error: testResultError,
    isLoading: testEvaluationIsLoading,
    mutate: refreshTestResult,
    reset,
  } = useTestEvaluation();

  const { data: groupId } = useGroupId(accountSelection);

  const packMappingSetQuery = usePackMappingSet({
    id,
    onSuccess: data => {
      dispatch(rehydrateMappingSet(createStateFromMappingSet(data)));
      setAccountSelection(data.accountId);
    },
  });

  const fulfillmentConfigurationQuery = useFulfillmentConfigurationSearch({
    id,
    onSuccess: data => {
      setIsUsedInFCs(data.total > 0);
    },
  });

  // NOTE: for proof of concept, the context only begins from FC, since we need to know which specific FC to redirect to
  // going from FDO => FC we think is an unlikely user path, but we can support that if there is a use case
  const {
    isLoading: isLoadingUcnContext,
    ucnId,
    fcData,
    originFcUrl,
  }: {
    isLoading: boolean;
    ucnId?: string;
    fcData?: FcData;
    originFcUrl?: string;
  } = useUcnContext();

  useAccountByUrl({
    accountUrl: fcData?._links.seller.href,
    onSuccess: account => {
      setAccountSelection(account.accountId);
    },
  });

  // if ucn id is present, persist it to view page
  const ucnIdQuery = ucnId ? `?ucnId=${ucnId}` : '';
  const gotoViewPage = ({ id }: { id: string }) => history.push(`/views/${id}${ucnIdQuery}`);
  const { mutate: createPackMappingSet, isLoading: creationLoading } = useNewPackMappingSet({
    onSuccess: gotoViewPage,
  });
  const { mutate: updatePackMappingSet, isLoading: updateLoading } = useUpdatePackMappingSet({
    onSuccess: gotoViewPage,
  });

  const onChangeMappingSet = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(editMappingSet({ field: name, value }));
  };

  const onChangeAccount = (selection: SelectField) => {
    setAccountSelection(selection.value);
  };

  const onAddCalculator = () => {
    dispatch(addPackMapping());
  };

  const publishAttributeModels = async (mappingSetCopy: MappingSetState) => {
    let attributeModelUrls;

    attributeModelUrls = await Promise.all(
      map(refs, async (ref, key) => {
        // If the ref never got set because a user didn't expand the accordion
        // to actually load the attribute model selector, just ignore it
        if (!ref) {
          return;
        }
        const { resourceUrl, status } = await ref.publish();
        if (status?.statusCode !== 200) {
          // When a user is saving no attribute model, component will return a 400 as validation but this is a valid action for us
          // Just ignore it and remove any attribute model url
          if (status?.statusMessage === 'No Attribute(s) are defined') {
            return { id: key, attributeModelUrl: '' };
          }
          return Promise.reject(status);
        }
        return { id: key, attributeModelUrl: resourceUrl };
      }),
    );

    forEach(compact(attributeModelUrls), ({ id, attributeModelUrl }) => {
      const mapping = find(mappingSetCopy.mappings, mapping => mapping.id === id);
      if (mapping) {
        mapping.attributeModelUrl = attributeModelUrl;
      }
    });
  };

  function checkError(err: any) {
    // Component already displays a snackbar for when no attribute values are selected
    if (err?.statusMessage === 'Some attribute(s) have no values assigned.') {
      return;
    }

    setShowSnackbar(true);
    setSnackbarDetails({ type: 'danger', details: 'Error publishing attribute models.' });
  }

  const onSave = async () => {
    const whiteListedInputs = createInitialPackCalculatorState();

    const mappingSetCopy = cloneDeep(mappingSet);
    mappingSetCopy.mappings.forEach(mapping => {
      mapping.packCalculator.inputs = whiteListedInputs.inputs;
    });

    try {
      await publishAttributeModels(mappingSetCopy);
    } catch (err) {
      checkError(err);

      // Exit from saving if an error occurred while trying to publish
      return;
    }

    if (id) {
      updatePackMappingSet({
        accountId: accountSelection,
        packMappingSetId: id,
        mappingSetState: mappingSetCopy,
        groupId,
      });
    } else {
      createPackMappingSet({ accountId: accountSelection, mappingSetState: mappingSetCopy, groupId });
    }
  };

  const navigateToFcUI = () => originFcUrl && window.location.assign(originFcUrl);
  const onCancel = () => {
    if (id) {
      gotoViewPage({ id });
    } else {
      history.push('/');
    }
  };

  const onRequestTestEvaluation = async () => {
    if (!accountSelection) {
      return;
    }

    const mappingSetCopy = cloneDeep(mappingSet);
    try {
      await publishAttributeModels(mappingSetCopy);
    } catch (err) {
      checkError(err);

      // Exit from testing if an error occurred while trying to publish
      return;
    }

    const configUrl = await attributeSelector!.current!.getConfigurationUrl();
    const testMappings = createTestEvaluateRequestFromState(accountSelection, mappingSetCopy);
    refreshTestResult({
      deliverableQuantity: Number(deliverableQuantity),
      productConfigurationUrl: configUrl,
      packCalculatorMappingSet: testMappings,
    });
  };

  const onAttributesChanged = (productStateDescriptor: {
    attributes: { key: string; resolvedValue: string }[];
    state: 'fullyResolved' | 'partiallyResolved';
  }) => {
    if (productStateDescriptor.state === 'fullyResolved') {
      const quantity = productStateDescriptor.attributes.find(att => att.key.toLowerCase() === 'quantity');
      if (quantity) {
        setDeliverableQuantity(quantity.resolvedValue);
      }
    }
  };

  if (packMappingSetQuery.isError) {
    return (
      <div style={{ margin: 'auto', marginTop: '30px' }}>
        <ErrorDisplay errorMsg={formatMessage(messages.errorMessage)} />
      </div>
    );
  }

  if (packMappingSetQuery.isLoading || isLoadingUcnContext) {
    return <Loading />;
  }

  return (
    <div className="container-fluid">
      <h2>{id ? formatMessage(messages.managePacks) : formatMessage(messages.createPacks)}</h2>
      {fulfillmentConfigurationQuery.isFetched && isUsedInFCs && (
        <Alert status="warning" message={formatMessage(messages.usedInFCWarning)} />
      )}
      <div style={{ display: 'grid', gridTemplateColumns: '3fr 1fr', gridGap: '10px' }}>
        <Card>
          <div>
            <div style={{ width: '50%' }}>
              <TextField
                name="name"
                label={formatMessage(messages.name)}
                value={mappingSet.name}
                onChange={onChangeMappingSet}
                required
              />
              <AccountSelect value={accountSelection} onChange={onChangeAccount} isDisabled={id} required />
            </div>
            <hr />
            {testResultError && (
              <Alert
                title="Error evaluating the pack calculator"
                message={<CodeBlock code={JSON.stringify(testResultError, null, 2)} />}
              />
            )}
            {mappingSet.mappings.map((mapping: MappingState) => (
              <PackCalculatorMapping
                key={mapping.id}
                id={mapping.id}
                setRefs={setRefs}
                dispatch={dispatch}
                mapping={mapping}
                evaluationResult={mapping.id === testResult?.key ? testResult : undefined}
                resetEvaluation={reset}
              />
            ))}
            <Button onClick={onAddCalculator}>{formatMessage(messages.addFormula)}</Button>
          </div>
          <hr />
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <SaveButton
              style={{ marginRight: '5px' }}
              text={formatMessage(messages.save)}
              savingText={formatMessage(messages.saving)}
              isSaving={creationLoading || updateLoading}
              variant="primary"
              onClick={onSave}
              disabled={!validateMappingSet({ mappingSet, accountId: accountSelection })}
            />
            {ucnId && originFcUrl ? (
              <Tooltip contents={formatMessage(messages.navigateToFcUI)}>
                <Button onClick={navigateToFcUI}>{formatMessage(messages.cancel)}</Button>
              </Tooltip>
            ) : (
              <Button onClick={onCancel}>{formatMessage(messages.cancel)}</Button>
            )}
          </div>
        </Card>
        <Card>
          <div style={{ position: 'sticky', top: '16px' }}>
            <TextField label="SKU" onChange={e => setTestSku(e.target.value)} value={testSku} />
            {debouncedTestSku && (
              <AttributeSelector
                productId={debouncedTestSku}
                authToken={auth.getAccessToken()}
                onChange={onAttributesChanged}
                ref={attributeSelector}
              />
            )}
            <Button onClick={onRequestTestEvaluation} disabled={!deliverableQuantity || testEvaluationIsLoading}>
              {formatMessage(messages.test)}
            </Button>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default CreatePage;
